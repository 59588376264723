import React, { useState } from 'react';

import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

const EventModal = (props) => {
  const [event, setEvent] = useState(props.event);

  const startDate = new Date(event.start_date);
  const endDate = new Date(event.end_date);

  const eventStartDate = startDate?.toISOString()?.split('T')[0];
  const eventStartTime = startDate?.toLocaleTimeString();
  const eventEndTime = endDate?.toLocaleTimeString();

  const onChange = ({ target: { name, value } }) => {
    setEvent((e) => ({ ...e, [name]: value }));
  };

  const onSubmit = () => {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        start_date: event.start_date,
        end_date: event.end_date,
        latitude: event.latitude,
        longitude: event.longitude,
        guide_id: event.guide_id,
      }),
    };

    fetch('/api/v1/events', options)
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => console.error(error));

    //props.setIsOpen(false);
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => props.setIsOpen(false)}
      show={props.isOpen}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>Nieuw bosbad</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex align-items-center gap-2">
          <Form.Control name="start_date" onChange={onChange} htype="date" value={eventStartDate || ''} />
          <Form.Control name="start_date" onChange={onChange} type="time" value={eventStartTime || ''} />
          <Form.Control name="end_date" onChange={onChange} type="time" value={eventEndTime || ''} />
          <Form.Select>
            <option>Standaard locatie</option>
            <option>Locatie 2</option>
          </Form.Select>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-light" onClick={onSubmit}>
          Opslaan
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EventModal;
